import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'

Vue.use(Vuex)
//axios.defaults.baseURL = 'https://local.intranet'
axios.defaults.baseURL = 'https://www.jesperpus.games/api'


export default new Vuex.Store({
  state: {
      images:[]
  },
  mutations: {
      set_images:(state,images)=>{
            state.images =images;
            console.log("set_images")
            console.log(images)
      }
  },
  actions: {

    getProfile(context){
      return new Promise((resolve, reject) => {        
        // получаем данные формы и фомируем объект для отправки
        const formData = new FormData();
      // formData.append("username", username);
      const AuthStr = 'Bearer '.concat(localStorage.getItem("access_token")); 

        axios.post('/profile/getprofileinfo', formData,{
            headers: {"Accept": "application/json",  Authorization: AuthStr }
        })
        .then(response => {
              resolve(response)
        })
        .catch(error => {

              reject(error)
        })
        })
    },
    setNickname(context,nickname){
      return new Promise((resolve, reject) => {        
        // получаем данные формы и фомируем объект для отправки
        const formData = new FormData();
       formData.append("nickname", nickname);
       const AuthStr = 'Bearer '.concat(localStorage.getItem("access_token")); 

        axios.post('/profile/saveNickname', formData,{
            headers: {"Accept": "application/json",  Authorization: AuthStr }
        })
        .then(response => {
              resolve(response)
        })
        .catch(error => {

              reject(error)
        })
        })
    },
    addPuzzleShareStats(context,image){
      return new Promise((resolve, reject) => {        
        // получаем данные формы и фомируем объект для отправки
        const formData = new FormData();
       formData.append("image", image);
       const AuthStr = 'Bearer '.concat(localStorage.getItem("access_token")); 

        axios.post('/puzzle/addPuzzleShareStats', formData,{
            headers: {"Accept": "application/json",  Authorization: AuthStr }
        })
        .then(response => {
              resolve(response)
        })
        .catch(error => {

              reject(error)
        })
        })
    },
    getPuzzleImages(context){
      return new Promise((resolve, reject) => {        
        // получаем данные формы и фомируем объект для отправки
       const AuthStr = 'Bearer '.concat(localStorage.getItem("access_token")); 

        axios.post('/puzzle/getPuzzleImages', null,{
            headers: {"Accept": "application/json",  Authorization: AuthStr }
        })
        .then(response => {
              this.commit("set_images",response.data);
              resolve(response)
        })
        .catch(error => {

              reject(error)
        })
        })
    },

  },
  modules: {
   
  },
  getters:{
        images(state){
              return state.images;
        }
  }
})
